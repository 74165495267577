import React from 'react';

import Footer from '../components/Footer';
import PageLayout from '../components/PageLayout';

export default function CoachDashboard() {
  return (
    <PageLayout>
      <div>coach</div>
      <Footer />
    </PageLayout>
  );
}
