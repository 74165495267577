import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setInvitationsContext } from '../actions/InvitationsActions';
import { COMPARISON_SIGNS } from '../consts/CommonConstants';
import failureIcon from '../images/important_note.svg';
import successTick from '../images/success_tick_external.svg';
import parseHTML from '../services/HTMLParser';
import httpMessenger from '../services/HTTPMessenger';
import userProfileService from '../services/UserProfileService';
import localStorageService from '../Shared/LocalStorageService';
import Spinner from '../Shared/Spinner';
import validationService from '../Shared/ValidationService';
import styles from '../styles/Dashboard.module.css';
import DomRender from './DomRender';
import EmptyPage from './EmptyPage';
import Footer from './Footer';
import Header from './Header';
import PageLayout from './PageLayout';
import Banner from './UI/Banner';
import Modal from './UI/Modal';
import Table from './UI/Table';

const Dashboard = () => {
  const { t } = useTranslation();
  const [dashboardList, setdashboardList] = useState([]);
  const [availableUnits, setAvailableUnits] = useState(null);
  const [isUnitsLow, setIsUnitsLow] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const [unitsLowMessage, setUnitsLowMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emptyMessage, setEmptyMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageParams, setErrorMessageParams] = useState({});
  const [DCSessions, setDCSessions] = useState(null);

  const ColumnHeaders = [
    { title: '' },
    { title: t('user.dashboard.product-group') },
    { title: t('user.dashboard.active-invitations') },
    {
      title: t('user.dashboard.price'),
      tooltipText: t('user.dashboard.no-of-units-per-invitation'),
    },
    { title: '' },
  ];

  const goToCreateInvitation = (
    productName,
    planId,
    productId,
    availableUnits,
    pricePerInvitation,
  ) => {
    dispatch(
      setInvitationsContext({
        productName,
        planId,
        productId,
        availableUnits,
        pricePerInvitation,
      }),
    );
    navigate(`/createinvitations`);
  };

  const customHeaderStyles = {
    header_container: styles.additional_style_header_container,
  };

  const goToCreatedInvitations = (
    productName,
    planId,
    productId,
    pricePerInvitation,
    pricePerDC,
  ) => {
    dispatch(
      setInvitationsContext({
        productName,
        planId,
        productId,
        availableUnits,
        pricePerInvitation,
        pricePerDC,
      }),
    );
    navigate(`/createdinvitations`);
  };

  useEffect(() => {
    getDashboardItems();
  }, []);

  const getUnitsLowMessage = () => {
    let displayMessage = [];
    unitsLowMessage.forEach((message) => {
      displayMessage.push(t(message.key, message.parameters));
    });
    return displayMessage.join(' ');
  };

  const getDashboardItems = async () => {
    const httpResult = await httpMessenger.apiCall('GET', 'planConsumption');
    setIsLoading(false);
    let dashboardListData;
    if (httpResult.success) {
      dashboardListData = httpResult.result;
      if (dashboardListData) {
        setIsUnitsLow(dashboardListData.unitsLowWarning);
        if (dashboardListData.unitsLowWarning) setShowWarningBanner(true);
        setAvailableUnits(dashboardListData.unitsAvailable);
        dashboardListData.unitsAvailable === 0
          ? setUnitsLowMessage([
              {
                key: 'user-dashboard.no-units-left',
                parameters: null,
              },
              {
                key: 'user-dashboard.contact-for-units',
                parameters: null,
              },
            ])
          : setUnitsLowMessage([
              {
                key: 'user-dashboard.less-units',
                parameters: null,
              },
              {
                key: 'user-dashboard.max-units-usable',
                parameters: {
                  num: dashboardListData.unitsAvailable,
                },
              },
            ]);
        setdashboardList([...dashboardListData.productGroups]);
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setEmptyMessage(errorMessage.join(' '));
    }
  };

  const displayDashboardList = () => {
    let tableBody = transformDashboardData();
    return (
      <Table
        theadData={ColumnHeaders}
        tbodyData={tableBody}
        customClass={styles.custom_table_class}
      />
    );
  };

  const goToTeamAssessmentDetails = (productName) => {
    localStorageService.setLocalStorageValue('product', productName);
    navigate('/teamAssessment');
  };

  const getEmailContent = (productName) => {
    let newLine = '%0A';
    let link = 'mailto:hello@zortify.com';
    let subject =
      'subject=' +
      'AD ' +
      userProfileService.getCompany() +
      ' - ' +
      t('user.contact.request-item', { item: productName ? t(productName) : t('general.units') });
    let body =
      'body=' +
      t('user.contact.dear-zortify-team') +
      newLine +
      (productName
        ? t('user.contact.we-need-more-info', { product: t(productName) })
        : t('user.contact.we-need-units') + ' (' + t('user.contact.add-number-of-units') + ')') +
      newLine;
    body = body + t('user.contact.contact-us-asap') + newLine + newLine;
    body = body + t('general.thank-you');

    return {
      text: productName ? t('user.dashboard.request-information') : t('user.contact.title'),
      link: link + '?' + subject + '&' + body,
      style: productName
        ? styles.dashboard_create_invitation_button_style
        : availableUnits === 0
        ? styles.error_button_style
        : styles.warning_button_style,
    };
  };

  const hideBanner = () => {
    setShowWarningBanner(false);
  };

  const getDCHeaderHint = () => {
    let finalDescription =
      '<span style ="font-size: 12px; font-weight: bolder; font-family: poppins">PLACE_HOLDER</span><br/><span style="font-size: 12px; opacity: 0.7; font-family: poppins">PLACE_HOLDER</span><br/><br/><span style="font-size: 12px; font-weight: bolder; font-family: poppins">PLACE_HOLDER</span><br/> <span style="font-size: 12px; opacity: 0.7; font-family: poppins">PLACE_HOLDER</span>';
    let descriptionKeys = [
      'general.debriefing',
      'product-details.debriefing-description',
      'general.coaching',
      'product-details.coaching-description',
    ];
    descriptionKeys.forEach((key) => {
      finalDescription = finalDescription.replace('PLACE_HOLDER', t(key));
    });
    return parseHTML(finalDescription);
  };

  const cancelDCSessionsRequest = () => {
    showDebriefingRequestModal();
  };

  const confirmDCRequest = async () => {
    const body = {
      amount: DCSessions,
    };
    let httpResult = await httpMessenger.apiCall('POST', 'sendUnlinkedDCRequest', body);
    if (httpResult.success) {
      setModalInfo({
        show: true,
        text: parseHTML(
          t('thanks-for-booking-debriefing-and-coaching-text1') +
            '<br/>' +
            t('thanks-for-booking-debriefing-and-coaching-text2'),
        ),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
          },
        },
        icon: successTick,
      });
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        show: true,
        text:
          errorMessage.join(' ') ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const sendDCRequest = () => {
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    let isValid;
    isValid = handleValidations(debriefingCoachingForm.instantValidations, DCSessions);
    if (isValid) {
      isValid = handleValidations(debriefingCoachingForm.submitValidations, DCSessions);
    }
    if (isValid) {
      setModalInfo({
        show: true,
        text: parseHTML(
          t('product-details.total-price-of-sessions', {
            total: DCSessions * 1, //TODO: Replace 1 by actual price per DC session
            amountOfSessions: DCSessions,
          }) +
            '<br/><br/>' +
            t('total-units-create-invitations', {
              total: DCSessions * 1, //TODO: Replace 1 by actual price per DC session
            }),
        ),
        formActions: {
          rightButton: {
            text: t('general.confirm'),
            action: () => confirmDCRequest(),
            style: styles.modal_submit_button_style,
          },
          leftButton: {
            text: t('general.cancel'),
            action: (e) => cancelDCSessionsRequest(),
            style: styles.modal_cancel_button_style,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      formActions: null,
      headerHint: null,
      headerText: null,
    });
    setError(false);
    setErrorMessage('');
    setDCSessions(null);
  };

  const handleValidations = (validations, validateValue) => {
    let isValid,
      message,
      messageParams = {};
    if (validations) {
      for (let validation of validations) {
        [isValid, message, messageParams] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError(true);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
          break;
        } else {
          setError(false);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
        }
      }
      return isValid ? true : false;
    } else return true;
  };

  const getDebriefingAndCoachingForm = () => {
    return {
      label: '',
      type: 'text',
      inputType: 'number',
      additionalStyles: {
        container: styles.sesions_input_container,
      },
      placeholder: '0',
      instantValidations: [
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.GT,
            expectation: 0,
            errorMessage: 'error.value.invalid-sessions',
            errorMessageParams: {},
          },
        },
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.LTEQ,
            expectation: Math.floor(availableUnits / 1), //TODO: Replace 1 by actual price per DC session
            errorMessage: 'error.value.units-gt-x',
            errorMessageParams: {
              max: Math.floor(availableUnits / 1), //TODO: Replace 1 by actual price per DC session
            },
          },
        },
      ],
      submitValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    };
  };

  const getDebriefingCoachingProps = () => {
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    let updatedProps = {
      required:
        (debriefingCoachingForm.submitValidations &&
          debriefingCoachingForm.submitValidations.some(
            (validation) => validation.type === 'required',
          )) ||
        (debriefingCoachingForm.instantValidations &&
          debriefingCoachingForm.instantValidations.some(
            (validation) => validation.type === 'required',
          )),
      additionalStyles: debriefingCoachingForm.additionalStyles,
      inputType: debriefingCoachingForm.inputType,
      placeholder: debriefingCoachingForm.placeholder,
      defaultValue: DCSessions,
      error: error,
      errorMessage: errorMessage,
      errorMessageParams: errorMessageParams,
    };
    return updatedProps;
  };

  const handleChange = (value) => {
    setDCSessions(value);
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    if (debriefingCoachingForm.instantValidations) {
      handleValidations(debriefingCoachingForm.instantValidations, value);
    } else {
      setError(false);
      setErrorMessage('');
      setErrorMessageParams({});
    }
  };

  const showDebriefingRequestModal = () => {
    setModalInfo({
      show: true,
      headerText: t('product-group.name.debriefing-and-coaching'),
      headerHint: getDCHeaderHint(),
      showDCForm: true,
      formActions: { ...getDebriefingCoachingModalFormActions() },
    });
  };

  const getDebriefingCoachingModalFormActions = () => {
    return {
      rightButton: {
        text: t('general.send-request'),
        action: () => sendDCRequest(),
        style: styles.modal_submit_button_style,
      },
      leftButton: {
        text: t('general.cancel'),
        action: modalClose,
        style: styles.modal_cancel_button_style,
      },
    };
  };

  useEffect(() => {
    /* This does not change the value of modalInfo, but updates the values inside
    the function calls handleSubmit and closeDialog to hold the latest
    values of the useState variable (payload) */
    if (modalInfo.show)
      setModalInfo((modalInfo) => ({
        ...modalInfo,
        ...{ formActions: getDebriefingCoachingModalFormActions() },
      }));
  }, [DCSessions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActions = (
    productName,
    planId,
    productGroupLabel,
    productId,
    pricePerInvitation,
    pricePerDC,
  ) => {
    let actions;
    if (
      productGroupLabel === 'product-group.name.team-assessments'
      // productGroupLabel === 'product-group.name.human-experience-services'
    ) {
      actions = [
        {
          elemType: 'link',
          props: { ...getEmailContent(productName) },
        },
        {
          elemType: 'button',
          metadata: {
            text: t('user.dashboard.product-details'),
            click: () => goToTeamAssessmentDetails(productName),
            style: styles.dashboard_create_invitation_button_style,
          },
        },
      ];
    } else if (productGroupLabel === 'product-group.name.human-experience-services') {
      actions = [
        {
          elemType: 'button',
          metadata: {
            text: t('debriefing-coaching.request-session'),
            click: showDebriefingRequestModal,
            style: styles.dashboard_create_invitation_button_style,
          },
        },
        {
          elemType: 'button',
          props: {
            disabled: true,
          },
          metadata: {
            text: t('user.dashboard.product-details'),
            click: () => {},
            style: styles.dashboard_create_invitation_button_style,
          },
        },
      ];
    } else {
      actions = [
        {
          elemType: 'button',
          metadata: {
            text: t('user.dashboard.create-invitations'),
            click: () =>
              goToCreateInvitation(
                productName,
                planId,
                productId,
                availableUnits,
                pricePerInvitation,
              ),
            style: styles.dashboard_create_invitation_button_style,
          },
        },
        {
          elemType: 'button',
          metadata: {
            text: t('user.dashboard.product-details'),
            click: () =>
              goToCreatedInvitations(
                productName,
                planId,
                productId,
                pricePerInvitation,
                pricePerDC,
              ),
            style: styles.dashboard_create_invitation_button_style,
          },
        },
      ];
    }
    return actions;
  };

  const transformDashboardData = () => {
    let debriefingAndCoachingGroup = dashboardList.find(
      (group) => group.labelKey === 'product-group.name.human-experience-services',
    );
    let pricePerDC = -1;
    if (debriefingAndCoachingGroup !== undefined) {
      if (debriefingAndCoachingGroup.products) {
        let DCProduct = debriefingAndCoachingGroup.products.find(
          // (product) => product.name === 'Debriefing & Coaching'
          (product) => product.name === 'product-group.name.debriefing-and-coaching',
        );
        pricePerDC = DCProduct.price;
      }
    }

    let transformedDataList = dashboardList.reduce((list, productGroup, productGroupIndex) => {
      let baseRow = [
        {
          content: t(productGroup.labelKey),
          additionalStyle: styles.highlight_product_group,
        },
        { content: '' },
        { content: '' },
        { content: '' },
      ];
      let borderTopStyle = styles.border_top;
      let borderBottomStyle = styles.border_bottom;
      let children = productGroup.products.reduce((children, product, index) => {
        let childData = [
          {
            content: '',
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
          {
            content: t(product.name),
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
          {
            content: product.invitationsGenerated !== 0 ? product.invitationsGenerated : '',
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
          {
            content: product.price !== 0 ? product.price : '',
            additionalStyle:
              index === 0
                ? borderTopStyle
                : productGroupIndex !== dashboardList.length - 1 &&
                  index === productGroup.products.length - 1
                ? borderBottomStyle
                : null,
          },
        ];
        let actions = getActions(
          product.name,
          productGroup.id,
          productGroup.labelKey,
          product.id,
          product.price,
          pricePerDC,
        );
        let child = Object.assign(
          {},
          {
            id: product.id,
            data: childData,
            actions: {
              content: [...actions],
              additionalStyle:
                index === 0
                  ? borderTopStyle
                  : productGroupIndex !== dashboardList.length - 1 &&
                    index === productGroup.products.length - 1
                  ? borderBottomStyle
                  : null,
            },
          },
        );
        children.push(child);
        return children;
      }, []);
      let rowData = Object.assign(
        {},
        {
          id: productGroup.id,
          data: [...baseRow],
          children: [...children],
        },
      );
      list.push(rowData);
      return list;
    }, []);
    return transformedDataList;
  };

  return (
    <PageLayout>
      <FlexView column className={styles.dashboard_container}>
        <Header
          title={userProfileService.getCompany() + ' Dashboard'}
          customStyles={customHeaderStyles}
          moreDetails={{ [t('user.dashboard.available-units')]: availableUnits }}
        />
        <FlexView className={styles.dashboard_table} column>
          {isUnitsLow && showWarningBanner && (
            <Banner
              error={availableUnits === 0}
              message={getUnitsLowMessage()}
              hyperlink={getEmailContent()}
              callbackAfterClose={hideBanner}
            />
          )}
          {dashboardList.length > 0 && displayDashboardList()}
          {isLoading && <Spinner />}
        </FlexView>
        {!isLoading && dashboardList.length <= 0 && (
          <EmptyPage message={emptyMessage || t('error.no-product-to-display')} />
        )}
        {modalInfo.show && (
          <Modal
            formActions={modalInfo.formActions}
            headerText={modalInfo.headerText}
            headerHint={modalInfo.headerHint}
            onClose={modalClose}
          >
            <FlexView column className={styles.modal_content_container}>
              {modalInfo.icon && (
                <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
              )}
              <span className={styles.modal_content_text}>{modalInfo.text}</span>
              {modalInfo.showDCForm && (
                <>
                  <span className={styles.modal_debriefing_coaching_content}>
                    {t('book-debriefing-and-coaching-text1') +
                      ' ' +
                      t('book-debriefing-and-coaching-text2')}
                  </span>
                  <span className={styles.modal_debriefing_coaching_content}>
                    {t('book-debriefing-and-coaching.select-amount-of-sessions')}
                  </span>
                  <DomRender
                    elemType={'text'}
                    {...getDebriefingCoachingProps()}
                    callback={(value) => handleChange(value)}
                  />
                </>
              )}
            </FlexView>
          </Modal>
        )}
        <Footer />
      </FlexView>
    </PageLayout>
  );
};
export default Dashboard;
