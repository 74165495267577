import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setUserListContext } from '../../actions/UserActions';
import CreateUser from '../../Admin/CreateUser/CreateUser';
import EmptyPage from '../../components/EmptyPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageLayout from '../../components/PageLayout';
import Chip from '../../components/UI/Chip';
import Input from '../../components/UI/Input';
import Table from '../../components/UI/PaginatedTable/Table';
import Snackbar from '../../components/UI/Snackbar';
import Switch from '../../components/UI/Switch';
import { UNDEFINED } from '../../consts/CommonConstants';
import { AD_ADMIN } from '../../consts/Roles';
import httpMessenger from '../../services/HTTPMessenger';
import userProfileService from '../../services/UserProfileService';
import Spinner from '../../Shared/Spinner';
import CompanyRoleMapper from './CompanyRoleMapper';
import styles from './Users.module.css';
import { STATUS } from './UserStatus';

const columns = {
  firstName: {
    title: 'FIRST NAME',
  },
  lastName: {
    title: 'FAMILY NAME',
  },
  email: {
    title: 'EMAIL',
  },
  enabled: {
    title: 'STATUS',
    customElement: true,
  },
  actions: {
    title: '',
  },
};

function Users() {
  const { t } = useTranslation();
  const IS_ADMIN = userProfileService.hasRoles([AD_ADMIN]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); //No.of rows per page
  const [dataLength, setDataLength] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [addNewUserModal, setShowAddNewUserModal] = useState(false);
  const [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    message: '',
  });
  const [errorMessage, setErrorMessage] = useState(UNDEFINED);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableCustomStyles = {
    tableCell: styles.tableCell,
  };

  const inputMetadata = {
    additionalStyles: {
      input: styles.search_input_additional_style,
      container: styles.search_input_container,
    },
    placeholder: 'Search',
    hint: 'Search by first name, last name, email or company starting with...',
  };

  const customHeaderStyles = {
    header_container: styles.additional_style_header_container,
  };
  const headerActionButtonConfig = () => {
    return {
      elemType: 'button',
      metadata: {
        text: '+ Add a new user',
        click: () => showAddNewUserModal(true),
        style: styles.custom_header_button_style,
      },
    };
  };

  const showAddNewUserModal = async (value) => {
    setShowAddNewUserModal(value);
  };

  const companyRoleMapper = new CompanyRoleMapper();

  //Function to render custom chip element for status in table.
  const getStatusElement = (userId, status, actionsCallback) => {
    let userStatus = status ? STATUS.ENABLED.name : STATUS.DISABLED.name;
    const element = IS_ADMIN
      ? {
          elemType: Switch,
          props: {
            checked: status,
            callback: (status) => toggleUserAccess(userId, status, actionsCallback),
          },
        }
      : {
          elemType: Chip,
          props: {
            metadata: {
              text: userStatus,
              style: Object.assign({}, STATUS[userStatus].style),
            },
          },
        };
    return element;
  };

  const getActions = (userId) => {
    const actions = [
      {
        elemType: 'button',
        metadata: {
          text: 'User Details',
          click: () => goToUserDetails(userId),
          style: styles.user_details_button_style,
        },
      },
    ];
    return actions;
  };

  const goToUserDetails = (userId) => {
    navigate(`/userdetails?userId=${userId}`);
  };

  const toggleUserAccess = async (userId, status, actionsCallback) => {
    const httpResult = await httpMessenger.apiCall(
      'PUT',
      'adminUser',
      null,
      null,
      `${userId}/enabled`,
    );
    if (httpResult.success) {
      let message = 'User has been successfully ' + (status ? 'enabled.' : 'disabled.');
      actionsCallback(
        userId,
        'enabled',
        getStatusElement(userId, status, actionsCallback),
        message,
      );
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      actionsCallback(null, null, null, errorMessage.join(' '));
    }
  };

  const loadUsers = async (pageNumber, shouldResetData, searchText) => {
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall('GET', 'user', {
      pageNumber,
      pageSize,
      searchTerm: searchText,
    });
    setIsLoading(false);
    if (httpResult.success) {
      const dataLen = httpResult.result.page.totalElements;
      setDataLength(dataLen);
      let users = [];
      httpResult.result.content.forEach((content) => {
        users.push({
          id: content.id,
          firstName: content.firstName,
          lastName: content.lastName,
          email: content.email,
          memberships: companyRoleMapper.getCompanyRoleMapping(content.memberships),
          enabled: getStatusElement(content.id, content.enabled, updateField),
          actions: getActions(content.id),
        });
      });
      let userListCopy = [...userList];
      if (userListCopy.length === 0 || shouldResetData) {
        userListCopy = new Array(dataLen);
        userListCopy.fill({}, 0);
        setErrorMessage('No users found.');
      }
      const startIndex = (pageNumber - 1) * pageSize;
      userListCopy.splice(startIndex, users.length, ...users);
      setUserList(userListCopy);
      dispatch(setUserListContext([...userListCopy]));
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setErrorMessage(errorMessage.join(' '));
    }
  };

  const handleSearchSubmit = async (event, searchText) => {
    if (event) event.preventDefault();
    loadUsers(1, true, searchText);
  };

  const setSearch = (value) => {
    setSearchTerm(value);
    if (value === '') {
      setSearchTerm(null);
      handleSearchSubmit(null, null);
    }
  };

  const handleSnackbarClose = () => {
    setSnackBar({
      showSnackbar: false,
      message: '',
    });
  };

  //Update any column field of a particular user row.
  const updateField = (id, field, fieldValue, message) => {
    setUserList((previousUserList) => {
      let indexToUpdate = previousUserList.findIndex((user) => user.id === id);
      if (indexToUpdate > -1) {
        let userListCopy = [...previousUserList];
        userListCopy[indexToUpdate] = Object.assign({}, userListCopy[indexToUpdate], {
          [field]: fieldValue,
        });
        return userListCopy;
      } else return previousUserList;
    });
    if (message) {
      setSnackBar({
        showSnackbar: true,
        message,
      });
    }
  };

  const setPage = async (pageNumber) => {
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (Object.keys(userList[startIndexOfCurrentPage]).length === 0) {
      loadUsers(pageNumber, false, searchTerm);
    }
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const callback = resetItemsPerPage;
    return { callback };
  };

  useEffect(() => {
    loadUsers(1, true);
  }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout>
      <FlexView column>
        <Header
          title="Users Overview"
          customStyles={IS_ADMIN ? customHeaderStyles : null}
          customElement={IS_ADMIN ? headerActionButtonConfig() : null}
        />
        <FlexView className={styles.table_container} column>
          <FlexView shrink={0}>
            <form onSubmit={(e) => handleSearchSubmit(e, searchTerm)}>
              <Input {...inputMetadata} callback={setSearch} />
            </form>
          </FlexView>
          <Snackbar
            open={snackBar.showSnackbar}
            onClose={handleSnackbarClose}
            message={snackBar.message}
          />

          <FlexView column className={styles.table_container_2}>
            {dataLength > 0 && (
              <Table
                data={userList}
                dataLength={dataLength}
                customstyles={tableCustomStyles}
                columns={columns}
                rowsPerPage={pageSize}
                alwaysPaginated={true}
                itemsPerPageData={getItemsPerPageData()}
                callback={setPage}
              />
            )}
          </FlexView>
          {isLoading && <Spinner />}
          {!isLoading && dataLength <= 0 && <EmptyPage message={errorMessage} />}
        </FlexView>
        <CreateUser modalOpen={addNewUserModal} modalClose={() => showAddNewUserModal(false)} />
        <Footer />
      </FlexView>
    </PageLayout>
  );
}

EmptyPage.propTypes = {
  message: PropTypes.string,
};

export default Users;
