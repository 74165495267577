import React from 'react';

import Checkbox from '../components/UI/Checkbox';
import Chip from '../components/UI/Chip';
import DateTimeService from '../services/DateTimeService';
import httpMessenger from '../services/HTTPMessenger';
import Comment from './Comment';
import ConfigDisplay from './ConfigDisplay';
import styles from './CreatedInvitations.module.css';
import { STATUS } from './Status';

export default class CreatedInvitationsPresenter {
  dataLength = 0;
  dateTime = new DateTimeService();

  getDataLength = () => {
    return this.dataLength;
  };

  copyToClipboard = async (event, id, uri, actionsCallback) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(uri);
    const httpResult = await this.updateStatus(STATUS.COPIED.key, id);
    if (httpResult.success) {
      let statusElement = this.getStatusChip(STATUS.COPIED.key);
      actionsCallback(
        httpResult.result.id,
        'status',
        statusElement,
        'product-details-link-copied-to-clipboard',
        true,
      );
    } else actionsCallback(null, null, null, null, false, httpResult.error);
  };

  updateMultipleStatuses = async (status, IDs) => {
    let allResponsesOK = true;
    for (const index in IDs) {
      const httpResult = await this.updateStatus(status, IDs[index]);
      if (!httpResult.success) {
        if (allResponsesOK) allResponsesOK = false;
      }
    }
    return allResponsesOK;
  };

  updateStatus = async (status, ID) => {
    const body = {
      field: 'status',
      value: status,
    };
    const httpResult = await httpMessenger.apiCall('PATCH', 'invitationUpdate', null, body, null, [
      ID,
    ]);
    return httpResult;
  };

  getActions = (id, uri, actionsCallback, showDebriefingCoachingModal, invitationStatus) => {
    const actions = [
      {
        elemType: 'icon-button',
        metadata: {
          src:
            invitationStatus === STATUS.EXPIRED.key
              ? 'debriefing_coaching_disabled.svg'
              : 'debriefing_coaching.svg',
          tooltipText: 'product-details-request-debriefing-or-coaching',
          alt: 'request_debriefing_coaching',
          style: styles.icon_button_style,
          click:
            invitationStatus === STATUS.EXPIRED.key
              ? () => {}
              : (event) => showDebriefingCoachingModal(event, id),
          disabled: invitationStatus === STATUS.EXPIRED.key ? true : false,
        },
      },
      {
        elemType: 'icon-button',
        metadata: {
          src: 'copy_icon.svg',
          tooltipText: 'general.copy-link',
          alt: 'copyuritocb',
          style: styles.icon_button_style,
          click: (event) => this.copyToClipboard(event, id, uri, actionsCallback),
        },
      },
    ];
    return actions;
  };

  //Function to render custom comment element in table.
  getCommentElementWithProps = (id, comment, actionsCallback) => {
    const element = {
      elemType: Comment,
      props: { id, comment, actionsCallback },
    };
    return element;
  };

  getMultipleCopyElementWithProps = (id, setMultipleCopyIDs) => {
    const element = {
      elemType: Checkbox,
      props: {
        checked: false,
        callback: (value) => setMultipleCopyIDs(id, value),
      },
    };
    return element;
  };

  //Function to render custom chip element for status in table.
  getStatusChip = (status) => {
    const element = {
      elemType: Chip,
      props: {
        metadata: {
          currentStatus: STATUS[status].key,
          text: STATUS[status].name,
          style: Object.assign({}, STATUS[status].style),
        },
        shouldTranslate: true,
      },
    };
    return element;
  };

  configDisplay = (config, noOfCoachingSessions) => {
    let additionalCoachingSessionInfo = [];
    if (noOfCoachingSessions > 0) {
      additionalCoachingSessionInfo.push({
        label: 'debriefing-session',
        value: 1,
      });
      if (noOfCoachingSessions > 1) {
        additionalCoachingSessionInfo.push({
          label: 'coaching-session-s',
          value: noOfCoachingSessions - 1,
        });
      }
    }
    return React.createElement(ConfigDisplay, {
      config: [...config, ...additionalCoachingSessionInfo],
    });
  };

  getInvitations = async (
    pageSize,
    pageNumber,
    productId,
    actionsCallback,
    setMultipleCopyIDs,
    statusFilters,
    showDebriefingCoachingModal,
  ) => {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('pageSize', pageSize);
    urlSearchParams.append('pageNumber', pageNumber);
    urlSearchParams.append('productId', productId);
    if (statusFilters) {
      Object.keys(statusFilters).map((status) => {
        if (!statusFilters[status]) {
          urlSearchParams.append('excludeStatus', status);
        }
        return true;
      });
    }

    const httpResult = await httpMessenger.apiCall('GET', 'invitation', urlSearchParams);
    if (httpResult.success) {
      const createdInvitationsPm = { data: [], totalElements: null };
      if (httpResult.result && httpResult.result.content) {
        this.dataLength = createdInvitationsPm.totalElements = httpResult.result.page.totalElements;
        httpResult.result.content.forEach((content) => {
          createdInvitationsPm.data.push({
            id: content.id,
            detailedview: this.configDisplay(content.config, content.coachingSessions.length),
            multiplecopy: this.getMultipleCopyElementWithProps(content.id, setMultipleCopyIDs),
            email: content.email,
            uri: content.uri,
            created: this.dateTime.toLocaleDateString(content.created),
            expired: this.dateTime.toLocaleDateString(content.expired),
            comment: this.getCommentElementWithProps(content.id, content.comment, actionsCallback),
            token: content.token,
            status: this.getStatusChip(content.status),
            actions: this.getActions(
              content.id,
              content.uri,
              actionsCallback,
              showDebriefingCoachingModal,
              content.status,
            ),
          });
        });
        return {
          success: true,
          result: createdInvitationsPm.data,
        };
      }
    } else
      return {
        success: false,
        error: httpResult.error,
      };
  };
}
